import { useContext, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import Context from '../context/Context';
import Accordion from '../components/Accordion';
import Iframe from '../components/Iframe';

import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const Details = () => {
    const { appName, setAppName, criteria, currentDoc, setCurrentDoc, isAtsdr, setIsAtsdr, handleCriteriaReset } = useContext(Context);
    const [searchParams] = useSearchParams();
    const iframeFileTypes = ['html', 'htm', 'aspx', 'asp', 'pdf'];

    useEffect(() => {
        if (currentDoc) {
            const newOrigUrl = new URL(currentDoc['original_url']).hostname.split('.');
            
            // Remove the www of a url if it exists
            if (newOrigUrl[0].includes('www')) newOrigUrl.shift();
            
            setIsAtsdr(
                newOrigUrl[0].includes('atsdr') &&
                newOrigUrl[1].includes('cdc') &&
                newOrigUrl[2].includes('gov') ?
                    true : false
            );

            setAppName(isAtsdr ? 'CDC/ATSDR Archive' : 'CDC Archive');
        }
    }, [currentDoc, isAtsdr]);

    document.title = `${currentDoc?.title} | ${appName}`;

    return (
        <>
            <nav className="no-print" role="navigation">
                <ul className="list-none flex flow-x-sm pl-0">
                    {criteria && criteria.q.replace(/\s/g, '').length !== 0 && (
                        <li>
                            <Link
                                className="text-sm md:text-base btn p-0 md:px-3 md:py-2 border-0 inline-block md:bg-gray-200 md:hover:bg-gray-300 font-bold text-blue-link no-underline hover:underline"
                                to={`/results?q=${criteria.q}&start=${searchParams.get('start')}&rows=${searchParams.get('rows')}`}
                                title={`Back to Results`}
                                onClick={() => {
                                    setCurrentDoc(null);
                                    handleInteractionBeacon( appName, 'o', 'back-to-results-click' );
                                }}
                            >
                                <span className="flex">
                                    <svg className="fill-gray-800 w-[24px] mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="M160 776q-17 0-28.5-11.5T120 736q0-17 11.5-28.5T160 696q17 0 28.5 11.5T200 736q0 17-11.5 28.5T160 776Zm0-160q-17 0-28.5-11.5T120 576q0-17 11.5-28.5T160 536q17 0 28.5 11.5T200 576q0 17-11.5 28.5T160 616Zm0-160q-17 0-28.5-11.5T120 416q0-17 11.5-28.5T160 376q17 0 28.5 11.5T200 416q0 17-11.5 28.5T160 456Zm120 320v-80h560v80H280Zm0-160v-80h560v80H280Zm0-160v-80h560v80H280Z"/></svg>
                                    <span><span className="sr-only">Select to go </span>Back to results</span>
                                </span>
                            </Link>
                        </li>
                    )}

                    <li>
                        <Link
                            className="text-sm md:text-base btn p-0 md:px-3 md:py-2 border-0 inline-block md:bg-gray-200 md:hover:bg-gray-300 font-bold text-blue-link no-underline hover:underline"
                            to="/"
                            title={`CDC Archive Home`}
                            onClick={() => {
                                handleCriteriaReset();
                                handleInteractionBeacon( appName, 'o', 'archive-home-click' );
                            }}
                        >
                            <span className="flex">
                                <svg className="fill-gray-800 w-[20px] mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"><path d="M240 856h120V616h240v240h120V496L480 316 240 496v360Zm-80 80V456l320-240 320 240v480H520V696h-80v240H160Zm320-350Z"/></svg>
                                <span><span className="sr-only">Select to go to </span>{isAtsdr ? 'CDC/ATSDR' : 'CDC'} Archive Home</span>
                            </span>
                        </Link>
                    </li>
                </ul>
            </nav>

            {currentDoc?.title && <h2 className="md:text-3xl"><span dangerouslySetInnerHTML={{__html: currentDoc?.title}} /></h2>}

            <Accordion title={`More Details`} archiveDate={currentDoc?.date_archived_display.split(' ')[0]} originalUrl={currentDoc?.original_url} archiveUrl={currentDoc?.archive_url} description={currentDoc?.excerpt_txt} />

            {(
                currentDoc &&
                currentDoc?.archive_url &&
                currentDoc.archive_url && iframeFileTypes.includes(currentDoc.archive_url.split('.')[currentDoc.archive_url.split('.').length - 1])
            ) && (
                <>
                    <Iframe currentDoc={currentDoc} />
                </>
            )}
        </>
    )
}

export default Details;
