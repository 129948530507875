export const handleInteractionBeacon = ( eventLabel = '', eventType, eventValue ) => {
    if ( window.hasOwnProperty( '_satellite' ) ) {
        var dataObject = {};
        var _satellite = window._satellite;
        eventType = eventType || 'o';
        dataObject.ch = 'OADC';
        dataObject.pageName = document.title;
         
        dataObject.label = eventLabel;
        dataObject.interactionType = eventType;
        dataObject.interactionValue = 'ci-' + eventLabel + ': ' + eventValue;
 
        dataObject.prop46 = window.location.href;
 
        _satellite.track( 'interaction', dataObject );
    }
};
