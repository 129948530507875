const Button = (props) => {
    const {classNames, attributes, onClick, children} = props;

    return (
        <button className={`${classNames ? classNames : ''}`} {...attributes} onClick={onClick}>
            {children}
        </button>
    )
}

export default Button;
