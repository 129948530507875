import { useContext, useEffect, useState } from 'react';

import Context from '../context/Context';
import SearchResult from '../components/SearchResult';
import Search from '../components/Search';
import Pagination from '../components/Pagination';
import { handleEmptyStringCheck } from '../helpers/handleEmptyStringCheck';

const Results = () => {
    const { appName, criteria, docsFound, docs } = useContext(Context);
    const [indexGroup, setIndexGroup] = useState([]);
    const [currentIndexGroup, setCurrentIndexGroup] = useState([]);
    const [firstIndex, setFirstIndex] = useState(0);
    const [lastIndex, setLastIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    document.title = `Search Results | ${appName}`;

    // Recalc indexes whenever criteria and/or docsFound state changes
    useEffect(() => {
        const arrayGroup = [...Array(Math.ceil(docsFound / criteria.rows)).keys()];

        setIndexGroup(arrayGroup);
        setFirstIndex(arrayGroup[0]);
        setLastIndex(arrayGroup[arrayGroup.length - 1]);
    }, [criteria, docsFound]);

    // Recalc currentIndex
    useEffect(() => {
        setCurrentIndex(criteria.start / criteria.rows);
    }, [currentIndex]);

    // Recalc indexGroup
    // Render -5 and +5 from currentIndex
    useEffect(() => {
        if (currentIndex > 5) {
            setCurrentIndexGroup(indexGroup.slice(currentIndex - 5, currentIndex + 5));
        }
        else {
            setCurrentIndexGroup(indexGroup.slice(0, 10));
        }
    }, [indexGroup, currentIndex]);

    return (
        <>
            <h2>Search Results</h2>

            <Search setCurrentIndex={setCurrentIndex} />

            {criteria.q.replace(/\s/g, '').length > 0 && (
                <div className="md:flex md:justify-between md:items-center mb-8">
                    <p className="pr-4 text-sm md:text-base mb-1 md:mb-0">
                        <span><strong>{docsFound}</strong> search result{docsFound === 1 ? '' : 's'} for <span className="text-semibold text-amber font-semibold">"{criteria.q}"</span>. </span>
                        
                        <button className="text-blue-link hover:underline" onClick={() => {
                            const searchField = document.querySelector('#search');
                            searchField.focus();
                            
                            if (handleEmptyStringCheck(searchField.value)) {
                                searchField.select();
                            }
                        }}>Search again?</button>
                    </p>

                    {docsFound > criteria.rows && (
                        <Pagination classNames={`text-sm md:text-base no-flow`} indexGroup={indexGroup} currentIndexGroup={currentIndexGroup} firstIndex={firstIndex} lastIndex={lastIndex} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
                    )}
                </div>
            )}

            <ul className="flow-y">
                {docs?.map((doc, index) => {
                    return (
                        <li key={index} lang={doc.cdc_sys_lang_str && doc.cdc_sys_lang_str[0] !== 'en' ? doc.cdc_sys_lang_str[0] : ''}>
                            <SearchResult indexGroup={indexGroup} firstIndex={firstIndex} lastIndex={lastIndex} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} doc={doc} />
                        </li>
                    )
                })}
            </ul>

            {docsFound > criteria.rows && (
                <Pagination classNames={`text-sm md:text-base no-flow mt-10`} indexGroup={indexGroup} currentIndexGroup={currentIndexGroup} firstIndex={firstIndex} lastIndex={lastIndex} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
            )}
        </>
    )
}

export default Results;
