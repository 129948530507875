import { useState, useRef, useEffect } from 'react';

const SkipToContent = () => {
    const [active, setActive] = useState(false);
    const skipLink = useRef(null);

    useEffect(() => {
        skipLink.current.addEventListener('focus', () => {
            setActive(true);
        });

        skipLink.current.addEventListener('focusout', () => {
            setActive(false);
        });
    }, [skipLink]);

    return (
        <button
            className={`bg-white absolute p-1 ${active ? 'translate-y-[0%] underline' : 'translate-y-[-100%]'}`}
            ref={skipLink}
            onClick={() => {
                const main = document.querySelector('#main');
                const scrollTop = Math.round(main.getBoundingClientRect().top);

                window.scrollTo({ top: scrollTop });
                main.focus();
            }}
        >Skip to content</button>
    )
}

export default SkipToContent;
