import { useState, useEffect, useRef } from 'react';
import { Routes, Route, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Context from './context/Context';
import Layout from './templates/Layout';
import Home from './pages/Home';
import Results from './pages/Results';
import Details from './pages/Details';
import FourZeroFour from './pages/404';
import useScreen from './hooks/useScreen';

import { handleInitAdobeLaunch } from './helpers/handleInitAdobeLaunch';
import { handleInternalUrlCheck } from './helpers/handleInternalUrlCheck';
import { handleEmptyStringCheck } from './helpers/handleEmptyStringCheck';

import './App.css';

const App = () => {
	const API = window?.CDC_CONFIG?.solr;
	const defaultCriteria = {q: '', start: 0, rows: 10};

	const [firstVisit, setFirstVisit] = useState(true);
    const [appName, setAppName] = useState('CDC Archive');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [criteria, setCriteria] = useState(defaultCriteria);
	const [appData, setAppData] = useState(null);
    const [docsFound, setDocsFound] = useState(null);
    const [docs, setDocs] = useState(null);
    const [currentDoc, setCurrentDoc] = useState(null);
    const [isAtsdr, setIsAtsdr] = useState(false);
    const [isDebug, setIsDebug] = useState(false);
	
	const {screenType, screenWidth} = useScreen();
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams({});

	const params = Object.fromEntries(new URLSearchParams(location.search));

	useEffect(() => {
		const debugParam = searchParams.get('debug');
		
		if (debugParam || params.debug == 'true') setIsDebug(true);
		
		handleInitAdobeLaunch();
	}, []);

	useEffect(() => {
		// Update canonical link tag
		// This helps with Google indexing/crawling
		if (document.head.querySelector('link[rel="canonical"]')) document.head.querySelector('link[rel="canonical"]').href = !currentDoc ? window.location.href : `${window.location.origin}/#${location.pathname}?url=${currentDoc.original_url}`;

		if (location.pathname !== '/details' && appName !== 'CDC Archive') {
			setAppName('CDC Archive');
			setIsAtsdr(false);
		}

		// Set firstVisit state to false so we can start capturing metrics
		return () => setFirstVisit(false);
	}, [location.pathname, currentDoc]);

	useEffect(() => {
		const queryParam = searchParams.get('q');
		const startParam = searchParams.get('start');
		const rowsParam = searchParams.get('rows');
		const urlParam = searchParams.get('url');
		const archiveUrlParam = searchParams.get('archive_url');

		// Only initialize if we have a search query
		if (handleEmptyStringCheck(criteria.q)) {
			if (location.pathname === '/' || location.pathname === '/results') {
				// Check params for banned words
				if (!handleCheckParams(params)) {
					setCriteria((prevCriteria) => ({
						...prevCriteria,
						q: ''
					}));

					navigate(`/results?q=&start=${criteria.start}&rows=${criteria.rows}`);

					return;
				}

				handleDataFetch()
					.then((data) => {
						if (data.response.numFound === 1 || data.response.docs.length === 1) {
							const theDoc = data.response.docs[0];
							const isInternalDoc = handleInternalUrlCheck(theDoc);

							if (isInternalDoc) {
								setCurrentDoc(theDoc);
								navigate(`/details?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&url=${theDoc.original_url}`);
							}
						}
						// For some reason, partial URL's more than 50 chars do not return any results
						// Here we .slice()' it down to 50 chars and re-query
						// We don't want to update the criteria.q state because this will cut off the query string, and looks ugly
						else if (data.response.numFound < 1 && criteria.q.length > 50) {
							const newQuery = criteria.q.slice(-50, criteria.q.length);

							handleDataFetch(newQuery)
								.then((data) => {
									// Go to /details page if only one result
									if (data.response.numFound === 1 || data.response.docs.length === 1) {
										const theDoc = data.response.docs[0];
										const isInternalDoc = handleInternalUrlCheck(theDoc);
			
										if (isInternalDoc) {
											setCurrentDoc(theDoc);
											navigate(`/details?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&url=${theDoc.original_url}`);
										}
									}
									// Or go to /results page if multiple results
									else {
										navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
									}
								});
						}
						else {
							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
						}
					});
			}
		}
		else if (location.pathname === '/results') {
			if (!handleCheckParams(params)) {
				setCriteria((prevCriteria) => ({
					...prevCriteria,
					q: ''
				}));

				navigate(`/results?q=&start=${criteria.start}&rows=${criteria.rows}`);

				return;
			}

			if (queryParam && handleEmptyStringCheck(queryParam)) {
				if (urlParam) {
					handleDataFetchByUrl(urlParam);
				}
				else {
					setCriteria((prevCriteria) => ({
						...prevCriteria,
						q: queryParam,
						start: startParam ? startParam : prevCriteria.start,
						rows: rowsParam ? rowsParam : prevCriteria.rows
					}));
				}
			}
		}
		else if (location.pathname === '/details') {
			if (!handleCheckParams(params)) {
				setCriteria((prevCriteria) => ({
					...prevCriteria,
					q: ''
				}));

				navigate(`/details?q=`);

				return;
			}

			// Check for both urlParam and archiveUrlParam
			if (urlParam && archiveUrlParam) {
				handleDataFetchByUrl(urlParam)
					.then((data) => {
						// Get the target document
						const targetDoc = data.response.docs.filter((doc) => doc.archive_url == archiveUrlParam)[0];

						setCurrentDoc(targetDoc);

						// If no target
						if (!targetDoc) {
							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
						}
						else {
							navigate(`/details?url=${targetDoc.original_url}&archive_url=${targetDoc.archive_url}`);
						}
					});
			}
			// Check if archiveUrlParam and no queryParam
			else if (archiveUrlParam && !urlParam) {
				handleDataFetchByArchiveUrl(archiveUrlParam)
					.then((data) => {
						// Check if we have any responses (documents)
						if (data.response.docs.length) {
							// Get and Set the target document
							const targetDoc = data.response.docs.filter((doc) => doc.archive_url == archiveUrlParam)[0];
							setCurrentDoc(targetDoc);
							navigate(`/details?archive_url=${archiveUrlParam}`);
						}
						else {
							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}`);
						}
					});
			}
			// Check urlParam and no queryParam
			else if (urlParam && !queryParam) {
				handleDataFetchByUrl(urlParam)
					.then((data) => {
						if (data.response.numFound === 1 || data.response.docs.length === 1) {
							const theDoc = data.response.docs[0];
							const isInternalDoc = handleInternalUrlCheck(theDoc);

							if (isInternalDoc) {
								setCurrentDoc(theDoc);
								navigate(`/details?url=${theDoc.original_url}`);
							}
						}
						else {
							setCriteria((prevCriteria) => ({
								...prevCriteria,
								q: urlParam,
								start: startParam ? startParam : prevCriteria.start,
								rows: rowsParam ? rowsParam : prevCriteria.rows
							}));

							navigate(`/results?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&url=${urlParam}`);
						}
					});
			}
			// Check for both urlParam and queryParam
			else if (urlParam && queryParam) {
				setCriteria((prevCriteria) => ({
					...prevCriteria,
					q: queryParam,
					start: startParam ? startParam : prevCriteria.start,
					rows: rowsParam ? rowsParam : prevCriteria.rows
				}));

				handleDataFetchByUrlAndQuery(queryParam, urlParam)
					.then((data) => {
						const targetDocs = data.response.docs.filter((doc) => doc.original_url === urlParam);
						
						if (targetDocs.length == 1) {
							setCurrentDoc(targetDocs[0]);
						}
						else if (targetDocs.length > 1) {
							navigate(`/results?q=${queryParam}&start=${startParam}&rows=${rowsParam}&url=${urlParam}`);
						}
						else {
							// Nothing by that url
							// Redirecting to home page
							navigate(`/`);
						}
					})
			}
		}
	}, [criteria]);

	const handleDataFetch = async (query) => {
		setIsLoading(true);
		let json = {};

		try {
			let endpoint = await `${API}?q=${query ? query : criteria.q}&start=${criteria.start}&rows=${criteria.rows}&fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=date_archived_gmt:[* TO NOW-15MINUTE]`;
			let response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	const handleDataFetchByUrl = async (url) => {
		setIsLoading(true);
		let json = {};

		try {
			const endpoint = await `${API}?fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=original_url:"${url}"`;
			const response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	const handleDataFetchByArchiveUrl = async (url) => {
		setIsLoading(true);
		let json = {};

		try {
			const endpoint = await `${API}?fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=archive_url:"${url}"`;
			const response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	const handleDataFetchByUrlAndQuery = async (q, url) => {
		setIsLoading(true);
		let json = {};

		try {
			const endpoint = await `${API}?q=${q}&start=${criteria.start}&rows=${criteria.rows}&fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=original_url:"${url}"`;
			const response = await fetch(endpoint);

			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	const handleDataFetchByFileType = async (fileType) => {
		setIsLoading(true);
		let json = {};

		try {
			const endpoint = await `${API}?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&fl=title,date_archived,date_archived_display,original_url,archive_url,excerpt_txt,cdc_sys_lang_str,cdc_topic_str,cdc_topic_srch&fq=original_url:*${fileType}`;
			const response = await fetch(endpoint);
			
			if (response.status === 400) throw new Error(`Status ${response.status}. Try again or come back later.`);
			
			json = await response.json();

			setAppData(json);
			setDocsFound(json.response.numFound);
			setDocs(json.response.docs);
		}
		catch(error) {
			setIsError(true);
			setErrorMessage(error);
		}
		finally {
			setIsLoading(false);

			return json;
		}
	};

	const handleCriteriaReset = () => {
		setCriteria(defaultCriteria);
		setAppData(null);
		setDocs(null);
		setDocsFound(null);
		setCurrentDoc(null);
	};

	const handleCheckParams = () => {
		const FILTERED_WORDS = 'telegram|instagram'.split('|');
		let pass = true;

		for (let param in params) {
			// @ symbol can be an email address or username
			if (params[param].match(/\@/)) {
				pass = false;
			}

			// url found in query param
			// if (params[param].match(/([\w-]+\.)+[\w-]{2,4}/)) {
			// 	pass = false;
			// }

			// banned words
			if (FILTERED_WORDS.includes(param.toLowerCase())) {
				pass = false;
			}

			if (FILTERED_WORDS.includes(params[param].toLowerCase())) {
				pass = false;
			}
		}

		return pass;
	};

	return (
		<Context.Provider value={{firstVisit, appName, setAppName, isLoading, setIsLoading, isError, errorMessage, criteria, setCriteria, appData, setAppData, docsFound, setDocsFound, docs, setDocs, currentDoc, setCurrentDoc, isAtsdr, setIsAtsdr, screenType, screenWidth, handleDataFetchByFileType, handleCriteriaReset}}>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="results" element={<Results />} />
					<Route path="details" element={<Details />} />
					<Route path="*" element={<FourZeroFour />} />
				</Route>
			</Routes>
		</Context.Provider>
	);
}

export default App;
