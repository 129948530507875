import { useState, useRef, useEffect } from 'react';

import Anchor from '../objects/Anchor';

import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const Accordion = (props) => {
    const { appName, title, archiveDate, originalUrl, archiveUrl, description } = props;
    const [active, setActive] = useState(false);
    const accordionBody = useRef(null);
    const name = title?.replace(/ /g, '-').toLowerCase();
    const btnId = `${name}-btn`;
    const bodyId = `${name}-body`;

    useEffect(() => {
        if (active) accordionBody.current.focus();
    }, [active]);

    const handleCopyDeepLinkBtn = (event, url) => {
        event.preventDefault();
        navigator.clipboard.writeText(`${window.location.origin}/#/details?url=${url}`);
    }

    return (
        <div className="accordion overflow-hidden">
            <button
                id={btnId}
                className={`text-blue-link relative z-10 px-4 py-2 border-t border-t-gray-[#f5f5f5] border-b border-b-gray-[#f5f5f5] w-full flex items-center justify-between ${active ? 'bg-[#f5f5f5]' : 'bg-white'}`}
                title={`Select for ${title}`}
                onClick={() => {
                    setActive(!active);
                    handleInteractionBeacon( appName, 'o', `accordion-${active ? 'close' : 'open'}-click` );
                }}
                aria-expanded={active ? true : false}
                aria-controls={bodyId}
            >
                <span>{title}</span>
                <svg className={`fill-gray-700 ${active ? 'rotate-180' : 'rotate-0'}`} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 96 960 960"><path d="M480 711 240 471l47.333-47.333L480 617.001l192.667-192.667L720 471.667 480 711Z"/></svg>
            </button>

            <div
                id={bodyId}
                className={`print flow-y relative z-0 p-4 border-b overflow-hidden ${active ? 'block' : 'hidden'}`}
                role="region"
                ref={accordionBody}
                tabIndex={active ? 0 : -1}
                aria-labelledby={btnId}
                aria-expanded={active ? true : false}
                aria-hidden={active ? false : true}
            >
                {archiveDate && <p><strong>Archive Date</strong>: {archiveDate}</p>}

                {originalUrl && <p><strong>Original URL</strong>: {originalUrl}</p>}

                {archiveUrl && <p><strong>Archive URL</strong>: {archiveUrl}</p>}

                {description && <p><strong>Description</strong>: <span dangerouslySetInnerHTML={{__html: description}} /></p>}

                <ul className="flex items-center flow-x">
                    {(
                        archiveUrl && archiveUrl?.substr(archiveUrl.length - 5, archiveUrl.length) !== '.html' &&
                        archiveUrl && archiveUrl?.substr(archiveUrl.length - 4, archiveUrl.length) !== '.htm' &&
                        archiveUrl && archiveUrl?.substr(archiveUrl.length - 5, archiveUrl.length) !== '.aspx' &&
                        archiveUrl && archiveUrl?.substr(archiveUrl.length - 4, archiveUrl.length) !== '.asp'
                    ) && (
                        <li>
                            <Anchor classNames="btn bg-blue-tertiary hover:bg-blue-secondary inline-flex"attributes={{
                                href: archiveUrl,
                                target: '_blank',
                                title: 'Download PDF Document'
                            }} onClick={() => {
                                handleInteractionBeacon( appName, 'o', `download-document-click` );
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960"><path d="M240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Zm240-160L280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200Z"/></svg>
                                <span className="ml-1">Download Document</span>
                            </Anchor>
                        </li>
                    )}
                    {originalUrl && (
                        <li>
                            <button className="text-blue hover:underline group" title="Copy link to clipboard" onClick={(event) => {
                                handleCopyDeepLinkBtn(event, originalUrl);
                                handleInteractionBeacon( appName, 'o', `clipboard-copy-click` );
                            }}>
                                <span className="flex items-center">
                                    <svg className="group-hover:fill-blue-link" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 -960 960 960"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z"/></svg>
                                    <span className="ml-1"><span className="sr-only">Select to </span>Copy link to clipboard</span>
                                </span>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default Accordion;
