export const handleInitAdobeLaunch = () => {
    if ( !document.body.classList.contains( 'adobe-launch-complete' ) ) {
        // Disables default pageview beacon call
        // window.CDC = {
        //     SPA_App: {},
        //     ABTest: {}
        // };

        // const tpJquery = document.createElement("script");
        // tpJquery.src = "https://www.cdc.gov/TemplatePackage/contrib/libs/jquery/latest/jquery.js";
        // tpJquery.async = true;
        // document.head.appendChild(tpJquery);

        const topicLevel = document.createElement("script");
        topicLevel.src = "https://www.cdc.gov/JScript/metrics/topic_levels.js";
        topicLevel.async = true;
        document.head.appendChild(topicLevel);

        const adobeLaunch = document.createElement("script");
        // adobeLaunch.src = "https://www.cdc.gov/JScript/metrics/adobe/launch/b36c1852e229/2c674008a127/launch-04da70ea688d.min.js"; // cdcgov
        // adobeLaunch.src = "https://assets.adobedtm.com/b36c1852e229/06d41b666fd2/launch-1e5bb452408a-development.min.js"; // cdcsynddev
        adobeLaunch.src = "https://assets.adobedtm.com/b36c1852e229/2c674008a127/launch-bdac63abfca9-development.min.js"; // devcdc
        adobeLaunch.async = true;
        document.head.appendChild(adobeLaunch);
    }
};
