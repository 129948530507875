import React from 'react';
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import './index.css';
import App from './App';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <HashRouter>
            <ScrollToTop />
            <App />
      </HashRouter>
    </React.StrictMode>
);
