import { Link } from 'react-router-dom';
import { useContext } from 'react';

import Context from '../context/Context';

import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const Footer = () => {
    const {appName, isAtsdr, handleCriteriaReset} = useContext(Context);

    return (
        <footer id="bottom" className="app-footer" role="contentinfo">
            <div className="bg-amber">
                <div className="wrapper">

                    <Link className="no-underline" to="/" title={`${appName} Home`} onClick={() => {
                        handleCriteriaReset();
                        handleInteractionBeacon( appName, 'o', 'footer-home-click' );
                    }}>
                        <p className="h2 text-gray-800 inline-block">{appName}</p>
                    </Link>

                </div>
            </div>
            <div className="bg-gray-800 py-2 xl:py-4">
                <div className="wrapper">
                    <nav role="navigation">
                        <ul className="text-sm md:flex md:flex-wrap xl:flex-nowrap xl:justify-between">
                            <li className="md:w-1/2 xl:w-auto">
                                {isAtsdr ? (
                                    <>
                                        <a className="text-white no-underline hover:underline" href="https://www.atsdr.cdc.gov/about/index.html" title="CDC Twenty Four Seven" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>About CDC/ATSDR</a>
                                    </>
                                ) : (
                                    <>
                                        <a className="text-white no-underline hover:underline" href="https://www.cdc.gov/about/index.html" title="CDC Twenty Four Seven" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>About CDC</a>
                                    </>
                                )}
                            </li>
                            <li className="md:w-1/2 xl:w-auto"><a className="text-white no-underline hover:underline" href="https://www.cdc.gov/other/accessibility.html" title="CDC Accessibility" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>Accessibility</a></li>
                            <li className="md:w-1/2 xl:w-auto xl:ml-2"><a className="text-white no-underline hover:underline" href="https://www.cdc.gov/other/privacy.html" title="CDC Digital Media Channel Privacy Policy Notice" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>Privacy</a></li>
                            <li className="md:w-1/2 xl:w-auto xl:ml-2"><a className="text-white no-underline hover:underline" href="https://www.cdc.gov/od/foia/" title="Freedom of Information Act (FOIA)" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>FOIA</a></li>
                            <li className="md:w-1/2 xl:w-auto xl:ml-2"><a className="text-white no-underline hover:underline" href="https://www.hhs.gov/" title="U.S. Department of Health & Human Services" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>U.S. Department of Health & Human Services</a></li>
                            <li className="md:w-1/2 xl:w-auto xl:ml-2"><a className="text-white no-underline hover:underline" href="https://www.cdc.gov/Other/policies.html" title="CDC Policies and Regulations" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>Policies</a></li>
                            <li className="md:w-1/2 xl:w-auto xl:ml-2"><a className="text-white no-underline hover:underline" href="https://www.usa.gov/" title="USA.gov" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>USA.gov</a></li>
                            <li className="md:w-1/2 xl:w-auto xl:ml-2"><a className="text-white no-underline hover:underline" href="https://www.cdc.gov/other/disclaimer.html#exit-notification" title="Exit Notification/Disclaimer Policy" target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', 'footer-link-click' )}>CDC Website Exit Disclaimer</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
