import { useContext } from 'react';
import { Link } from 'react-router-dom';

import Context from '../context/Context';

import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const SearchResult = (props) => {
    const {doc} = props;
    const { appName, criteria, setCurrentDoc, screenWidth } = useContext(Context);

    return (
        <Link
            className="group block text-left rounded hover:bg-gray-100 transition ease-in-out mx-[-.75rem] px-[.75rem] my-[-.65rem] py-[.65rem]"
            to={`/details?q=${criteria.q}&start=${criteria.start}&rows=${criteria.rows}&url=${doc.original_url}`}
            title={doc.title}
            onClick={() => {
                if ( window.hasOwnProperty( '_satellite' ) ) {
                    console.log('foo')
                    window['_satellite'].track( 'interaction', {
                        label: 'test-label',
                        interactionType: 'o',
                        interactionValue: 'ci-'
                    });
                }

                setCurrentDoc(doc);

                handleInteractionBeacon( appName, 'o', 'search-result-click' );
            }}
        >
            {/* Title and Archive date */}
            {doc.title && (
                <p className="text-md font-bold">
                    <span className="group-hover:underline text-blue-link" dangerouslySetInnerHTML={{__html: doc.title}} />

                    {doc.date_archived_display && (
                        <small className="font-normal block md:inline md:ml-2">Archived {doc.date_archived_display.split(' ')[0]}</small>
                    )}
                </p>
            )}

            {/* URL */}
            {doc.original_url ? (
                <p className={`text-gray-500 text-sm`}>{doc.original_url}</p>
            ) : (
                <>
                    {doc.archive_url && <p className={`text-gray-500 text-sm`}>{doc.archive_url}</p>}
                </>
            )}

            {/* Description with truncating */}
            {doc.excerpt_txt && doc.excerpt_txt.length > 0 && (
                <>
                    {screenWidth < 768 ? (
                        <p className="text-gray mt-2" dangerouslySetInnerHTML={{__html: doc.excerpt_txt.length < 150 ? doc.excerpt_txt : doc.excerpt_txt.slice(0, 150) + '...'}} />
                    ) : (
                        <p className="text-gray mt-2" dangerouslySetInnerHTML={{__html: doc.excerpt_txt.length < 300 ? doc.excerpt_txt : doc.excerpt_txt.slice(0, 300) + '...'}} />
                    )}
                </>
            )}
        </Link>
    )
}

export default SearchResult;
