import { useState } from "react";
import { useContext } from 'react';

import Context from '../context/Context';

import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const AlertMessage = () => {
    const { appName, isAtsdr } = useContext(Context);
    const [active, setActive] = useState(true);

    return (
        <div className={`alert bg-amber-tertiary relative ${!active && 'hidden'}`} role="banner">
            <div className="wrapper py-3">
                <h2 className="h4">This page is archived for historical purposes and is no longer being updated.</h2>
                <div className="flex mt-3">
                    <i>
                        <svg className="fill-amber" xmlns="http://www.w3.org/2000/svg" width="48" viewBox="0 0 109.84 95.894"><path d="M109.441 91.498 57.466 1.465C56.936.549 55.964 0 54.92 0s-2.015.568-2.546 1.465L.398 91.498a2.894 2.894 0 0 0 0 2.93 2.937 2.937 0 0 0 2.546 1.466h103.953a2.97 2.97 0 0 0 2.545-1.466 2.891 2.891 0 0 0-.001-2.93z"/><path fill="#FFF" d="M61.878 79.6A6.939 6.939 0 1 1 48 79.6a6.938 6.938 0 0 1 6.94-6.938 6.936 6.936 0 0 1 6.938 6.938zM54.939 30.878c-3.782 0-6.826 3.109-6.826 6.97l2.018 24.415c.385 3.158.714 4.948 2.018 5.789a6.689 6.689 0 0 0 2.79.607c.99 0 1.93-.217 2.777-.603 1.311-.84 1.645-2.631 2.03-5.796l2.019-24.415c-.001-3.858-3.044-6.967-6.826-6.967z"/></svg>
                    </i>
                    <p className="ml-4">This is archived content from the {isAtsdr ? 'CDC/ATSDR' : 'CDC'} website. The information here may be outdated and <strong>links may no longer function</strong>. Go to <a className="underline text-blue" href={isAtsdr ? 'https://www.atsdr.cdc.gov/' : 'https://www.cdc.gov'} target="_blank" onClick={() => handleInteractionBeacon( appName, 'o', `alert-cdcgov-click` )}>{isAtsdr ? 'CDC/ATSDR' : 'CDC'} Home</a> for all other recent information.</p>
                </div>
            </div>
            <button className="absolute top-2 right-2 opacity-50 hover:opacity-75" title="Close alert message" onClick={() => {
                setActive(false);
                handleInteractionBeacon( appName, 'o', `alert-close-click` );
            }}>
                <span className="sr-only">Close</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 96 960 960"><path d="m251.333 851.333-46.666-46.666L433.334 576 204.667 347.333l46.666-46.666L480 529.334l228.667-228.667 46.666 46.666L526.666 576l228.667 228.667-46.666 46.666L480 622.666 251.333 851.333Z"/></svg>
            </button>
        </div>
    )
}

export default AlertMessage;
