import { useContext } from 'react';

import Context from '../context/Context';

import { handleInteractionBeacon } from '../helpers/handleInteractionBeacon';

const Pagination = (props) => {
    const { classNames, currentIndexGroup, firstIndex, lastIndex, currentIndex, setCurrentIndex } = props;
    const { appName, criteria, setCriteria } = useContext(Context);

    const handlePaginate = (newCriteria, newIndex) => {
        setCriteria((prevCriteria) => ({
            ...prevCriteria,
            start: newCriteria
        }));

        setCurrentIndex(newIndex);

        const main = document.querySelector('#main');

        main.focus();
    };

    return (
        <>
            <ul className={`pagination flex items-center ${classNames ? classNames : ''}`}>
                {/* Sets up 'First' and 'Prev' paginate buttons */}
                {currentIndex > firstIndex && (
                    <>
                        <li className="pagination-item">
                            <button
                                className={`hover:underline border border-r-0 rounded-tl rounded-bl px-2 py-1`}
                                title="First page"
                                onClick={() => {
                                    handlePaginate(criteria.rows * firstIndex, firstIndex);
                                    handleInteractionBeacon( appName, 'o', 'paginate-first-click' );
                                }}
                            >First</button>
                        </li>

                        <li className="pagination-item">
                            <button
                                className={`hover:underline border border-r-0 px-2 py-1`}
                                title="Previous page"
                                onClick={() => {
                                    handlePaginate(criteria.rows * (currentIndex - 1), currentIndex - 1);
                                    handleInteractionBeacon( appName, 'o', 'paginate-prev-click' );
                                }}
                            >Prev</button>
                        </li>
                    </>
                )}
                
                {/* Sets up 'Current/Active' paginate button */}
                {currentIndexGroup.map((index) => {
                    return (
                        <li
                            className={`pagination-item ${currentIndex === index ? 'active' : ''}`}
                            key={index}
                            aria-label={`page${index+1}`}
                            aria-current={currentIndex === index ? 'page' : false}>
                            <button
                                className={`
                                     hover:underline border border-r-0 px-2 py-1
                                    ${currentIndex === index ? 'bg-blue text-white border-blue pointer-events-none' : ''} 
                                    ${currentIndex === firstIndex && currentIndex === index ? 'rounded-tl rounded-bl' : ''} 
                                    ${currentIndex === lastIndex && currentIndex === index ? 'rounded-tr rounded-br' : ''}
                                `}
                                title={`Page ${index + 1}`}
                                onClick={() => {
                                    handlePaginate(criteria.rows * index, index);
                                    handleInteractionBeacon( appName, 'o', `paginate-page_${index+1}-click` );
                                }}
                            >{index + 1}</button>
                        </li>
                    )
                })}

                {/* Sets up 'Next' and 'Last' paginate buttons */}
                {currentIndex < lastIndex && (
                    <>
                        <li className="pagination-item">
                            <button
                                className={`hover:underline border border-r-0 px-2 py-1`}
                                title="Next page"
                                onClick={() => {
                                    handlePaginate(criteria.rows * (currentIndex + 1), currentIndex + 1);
                                    handleInteractionBeacon( appName, 'o', 'paginate-next-click' );
                                }}
                            >Next</button>
                        </li>

                        <li className="pagination-item">
                            <button
                                className={`hover:underline border rounded-tr rounded-br px-2 py-1`}
                                title="Last page"
                                onClick={() => {
                                    handlePaginate(criteria.rows * lastIndex, lastIndex);
                                    handleInteractionBeacon( appName, 'o', 'paginate-last-click' );
                                }}
                            >Last</button>
                        </li>
                    </>
                )}
            </ul>
        </>
    )
}

export default Pagination;
